import { useState } from "react";
import {Box, Tab, Tabs, Slider, FormGroup, TextField, FormControlLabel, Checkbox, Autocomplete} from "@mui/material";
import { TestConfigId } from "../../../store/slices/maps/types";
import Logger from "../../../tech/utils/Logger";
import { BaseDialog } from "../base/BaseDialog";


interface DeliveryControllerDialogProps {
  testConfigId: TestConfigId;
  open: boolean;
  onClose: React.MouseEventHandler;
}

export default function DeliveryControllerLayoutDialog({
                                                         testConfigId,
  open,
  onClose,
}: DeliveryControllerDialogProps): React.ReactElement {

  const parcelpervehicle = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 200,
      label: "200",
    },
    {
      value: 400,
      label: "400",
    },
  ];

  const workingdays = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
  ];

  const deliverypercentage = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 50,
      label: "50",
    }, {
      value: 100,
      label: "100",
    },
  ];

  const ewt = [{ label: "Erwerbstätig" }, { label: "Nicht erwerbstätig" }];

  const pkw = [{ label: "Mit PKW" }, { label: "Ohne PKW" }];

  const gender = [
    { label: "männlich" },
    { label: "weiblich" },
    { label: "divers" },
  ];

  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentTabIdx(newValue);
  };


  const handleLoadLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", testConfigId);
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Voreinstellungen"
      primaryButton={{
        label: "Einstellungen speichern",
        onClick: handleLoadLayout,
      }}
    >
      <Tabs
        sx={{
          mx: (theme) => theme.spacing(-3),
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
        value={currentTabIdx}
        onChange={handleChangeTab}
      >
        <Tab sx={{ flexBasis: "33%" }} label="Liefereinstellungen heute" />
        <Tab sx={{ flexGrow: 1 }} label="Gewichtsfaktor 2030" />
        <Tab sx={{ flexBasis: "33%" }} label=" + Neues Szenario" />
      </Tabs>
      <Box
        sx={{
          minHeight: 170,
          pt: (theme) => theme.spacing(3),
          pl: (theme) => theme.spacing(4),
        }}
      >
        {currentTabIdx === 0 && (
            <>
              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Arbeitstage:
                <Slider
                    aria-label="working days"
                    defaultValue={7}
                    step={1}
                    marks={workingdays}
                    min={1}
                    max={7}
                    valueLabelDisplay="auto"/>
              </Box>
            <Box
                sx={{
                  width: 250,
                  py: 1,
                }}
            >
              Anzahl der Pakete pro Fahrzeug:
              <Slider
                  aria-label="prozentualdhl"
                  defaultValue={170}
                  step={1}
                  marks={parcelpervehicle}
                  min={0}
                  max={400}
                  valueLabelDisplay="auto"/>
            </Box>

              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Prozentualer Lieferanteil
                <Slider
                    aria-label="prozentualgewerbe"
                    defaultValue={23}
                    step={1}
                    marks={deliverypercentage}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                />
              </Box>
              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Prozentualer Anteil zusätzlicher Lieferungen
                <Slider
                    aria-label="prozentualour"
                    defaultValue={48}
                    step={1}
                    marks={deliverypercentage}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                />
              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    py: 1,
                  }}
              >
                <FormGroup>
                  <FormControlLabel
                      control={<Checkbox defaultChecked/>}
                      label="Lieferung nur an private Haushalte"/>
                </FormGroup>
              </Box>
              <Box
                  sx={{
                    width: 250,
                    py: 2,
                  }}
              >
                {" "}
                Pakete pro Haushalt pro Jahr:
                <TextField
                    id="deliveryhhpy"
                    label=""
                    variant="outlined"
                    size="small"
                    defaultValue="0"/>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-evenly",
                  width: 250,
                  py: 0,
                }}
            >
              Servicezeit pro Lieferung in Sekunden:
              <TextField
                  id="service"
                  label=""
                  variant="outlined"
                  size="small"
                  defaultValue="150"/>
            </Box>
          </Box>
            </>

        )}
        {currentTabIdx === 1 && (

            <>
              <Box sx={{
                width: '100%',
                px: 1,
                py: 2,
              }}>Sendung pro Tag für Altersgruppe definieren:
              </Box>
              <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                    width: 400,
                  }}
              >
                <TextField sx={{
                  m: 1,
                }}
                    required
                    id="outlined-required"
                    label="Kinder"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                    required
                    id="outlined-required"
                    label="Schüler"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                    required
                    id="outlined-required"
                    label="Student"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                  required
                  id="outlined-required"
                  label="Altersgruppe 25-34"
                  defaultValue="1.0"
              />
                <TextField sx={{m: 1,
              }}
                    required
                    id="outlined-required"
                    label="Altersgruppe 35-44"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                    required
                    id="outlined-required"
                    label="Altersgruppe 45-54"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                           required
                           id="outlined-required"
                           label="Altersgruppe 54-64"
                           defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                    required
                    id="outlined-required"
                    label="Altersgruppe 64-74"
                    defaultValue="1.0"
                />
                <TextField sx={{m: 1,
                }}
                           required
                           id="outlined-required"
                           label="Altersgruppe 75+"
                           defaultValue="1.0"
                />

              </Box>
              <Box sx={{
                py: 1,
              }}>Gewichtungsfaktoren anpassen für Sendungslieferungen:</Box>
              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
              >
                <Autocomplete
                    disablePortal
                    id="ewt"
                    options={ewt}
                    sx={{ width: 200, py: 1 }}
                    renderInput={(params) => (
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        <TextField {...params} label="Status EWT" />
                    )}
                />
              </Box>

              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
              >
                <Autocomplete
                    disablePortal
                    id="pkw"
                    options={pkw}
                    sx={{ width: 200, py: 1 }}
                    renderInput={(params) => (
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        <TextField {...params} label="Status PKW" />
                    )}
                />

              </Box>
              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
              >
                <Autocomplete
                    disablePortal
                    id="gender"
                    options={gender}
                    sx={{ width: 200, py: 1 }}
                    renderInput={(params) => (
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        <TextField {...params} label="Geschlecht" />
                    )}
                />
              </Box>
            </>

        )}
        {currentTabIdx === 2 && (
            <>
              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Arbeitstage:
                <Slider
                    aria-label="working days"
                    defaultValue={7}
                    step={1}
                    marks={workingdays}
                    min={1}
                    max={7}
                    valueLabelDisplay="auto"/>
              </Box>
              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Anzahl der Pakete pro Fahrzeug:
                <Slider
                    aria-label="prozentualdhl"
                    defaultValue={170}
                    step={1}
                    marks={parcelpervehicle}
                    min={0}
                    max={400}
                    valueLabelDisplay="auto"/>
              </Box>

              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Prozentualer Lieferanteil
                <Slider
                    aria-label="prozentualgewerbe"
                    defaultValue={23}
                    step={1}
                    marks={deliverypercentage}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                />
              </Box>
              <Box
                  sx={{
                    width: 250,
                    py: 1,
                  }}
              >
                Prozentualer Anteil zusätzlicher Lieferungen
                <Slider
                    aria-label="prozentualour"
                    defaultValue={48}
                    step={1}
                    marks={deliverypercentage}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                />
                <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: 1,
                      width: 300,
                    }}
                >
                  <FormGroup>
                    <FormControlLabel
                        control={<Checkbox defaultChecked/>}
                        label="Lieferung nur an private Haushalte"/>
                  </FormGroup>
                </Box>
                <Box
                    sx={{
                      width: 300,
                      py: 2,
                    }}
                >
                  {" "}
                  Pakete pro Haushalt pro Jahr:
                  <TextField
                      id="deliveryhhpy"
                      label=""
                      variant="outlined"
                      size="small"
                      defaultValue="0"/>
                </Box>
                <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "space-evenly",
                      width: 250,
                      py: 0,
                    }}
                >
                  Servicezeit pro Lieferung in Sekunden:
                  <TextField
                      id="service"
                      label=""
                      variant="outlined"
                      size="small"
                      defaultValue="150"/>
                </Box>
              </Box>
            </>
        )}
      </Box>
    </BaseDialog>
  );
}
