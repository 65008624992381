/* eslint-disable @typescript-eslint/no-explicit-any */
import { TileLayerProps } from "@deck.gl/geo-layers/tile-layer/tile-layer";
import { ModifyMode, ViewMode } from "@nebula.gl/edit-modes";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { GeoJsonLayer as DeckGLGeoJsonLayer } from "@deck.gl/layers";
import { TileLayer, BitmapLayer, PickInfo } from "deck.gl";
import {
  FlowData,
  GeoJsonData,
  HeatmapData,
  HeatmapDataInterval,
  Marker,
  MarkerData,
  WmsDataId,
} from "../../../api/layers/types";
import { getZensusInspireGridWmsTiles } from "../../../api/layers/zensus-wms-api";
import ClusterIconLayer from "../../../components/map/layer/ClusterIconLayer";
import FlowLayer from "../../../components/map/layer/FlowLayer";
import GeoJsonLayer from "../../../components/map/layer/GeoJsonLayer";
import HeatmapLayer from "../../../components/map/layer/HeatmapLayer";
import {
  HeatmapSettings,
  MobOpsLogDistrictDiffSettings,
  MobOpsLogDistrictSettings,
} from "../../../components/map/layer/types";
import WmsLayer from "../../../components/map/layer/WmsLayer";
import { PUBLIC_OSM_TILES_URL, OWN_OSM_TILES_URL } from "../../../config";
import {
  getMobOpsLogGeoJsonDistrictDiffLayerStyle,
  getMobOpsLogGeoJsonLayerStyle,
} from "../../../tech/utils/ColorPicker";
import Logger from "../../../tech/utils/Logger";
import {
  getStartOfDayMilliseconds,
  getEndOfDayMilliseconds,
  isoTimestampToDate,
} from "../../../tech/utils/TimestampFormatter";
import { store } from "../../store";
import { updateTooltipData, updateViewState } from "../maps/slice";
import { MapConfigId } from "../maps/types";
import {
  selectHeatmapSettingsById,
  selectMobOpsLogDistrictDiffSettingsById,
  selectMobOpsLogDistrictSettingsById,
  selectMobOpsLogRouteSettingsById,
  selectPoiSettingsById,
} from "./selectors";
import {
  clearMobOpsLogRouteData,
  clearPoiData,
  updateMobOpsLogDistrictData,
  updateMobOpsLogDistrictDiffColorAlpha,
  updateMobOpsLogDistrictDiffSettings,
  updateMobOpsLogDistrictSettings,
  updateTimeseriesSettings,
} from "./slice";
import {
  fetchFlowData,
  fetchGeoJsonData,
  fetchHeatmapData,
  fetchMarkerData,
  fetchMobOpsLogClusterData,
  fetchMobOpsLogDistrictData,
  fetchMobOpsLogRoutesData,
  fetchTazesGeoJsonData,
} from "./thunks";
import {
  isFlowData,
  isGeoJsonData,
  isHeatmapData,
  isLayerId,
  isMarker,
  isMarkerData,
  isWmsDataId,
} from "./type-guards";
import { Layer, LayerData, LayerId, ObjectsByLayerId } from "./types";

function dispatchTooltipData(
  mapConfigId: MapConfigId,
  hoverData: PickInfo<unknown>
): void {
  const { x, y, object } = hoverData;
  store.dispatch(
    updateTooltipData({ id: mapConfigId, tooltipData: { x, y, object } })
  );
}

export function createMarkerLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean,
  onLeafIconClick?: (clickData: unknown) => void
): any {
  let data: MarkerData | undefined;
  if (isMarkerData(layerData.data)) {
    data = layerData.data as MarkerData;
  }
  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];
  const mapConfig = state.mapReducer.mapConfigsById[layerConfig.mapConfigId];
  const layer = new ClusterIconLayer({
    id: layerId,
    data,
    onLeafIconClick,
    viewState: mapConfig.viewState,
    onViewStateChange: (viewState) =>
      store.dispatch(
        updateViewState({
          id: mapConfig.id,
          viewState,
        })
      ),
    onHover: (hoverData) => dispatchTooltipData(mapConfig.id, hoverData),
  });
  return layer.render(visible);
}

export function createHeatmapLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  let data: HeatmapData | undefined;
  if (isHeatmapData(layerData.data)) {
    data = layerData.data as HeatmapData;
  }
  const heatmapSettings: HeatmapSettings | null = selectHeatmapSettingsById(
    store.getState(),
    layerId
  );
  const layer = new HeatmapLayer({
    id: layerId,
    data,
    dataIndex: 0,
  });
  return layer.render(heatmapSettings, visible);
}

export function createGeoJsonLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  let data: GeoJsonData | undefined;
  if (isGeoJsonData(layerData.data)) {
    data = layerData.data as GeoJsonData;
  }
  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];
  const layer = new GeoJsonLayer({
    id: layerId,
    data,
    onHover: (hoverData) =>
      dispatchTooltipData(layerConfig.mapConfigId, hoverData),
  });
  return layer.render(visible);
}

export function createEditableGeoJsonLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  const mobOpsLogDistrictSettings: MobOpsLogDistrictSettings | null =
    selectMobOpsLogDistrictSettingsById(store.getState(), layerId);
  const { data } = layerData;
  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];
  return new EditableGeoJsonLayer({
    id: layerId,
    visible,
    data,
    mode:
      layerConfig.editComponentId === "enable" && layerConfig.editing
        ? ModifyMode
        : ViewMode,
    modeConfig: {},
    selectedFeatureIndexes:
      mobOpsLogDistrictSettings?.selectedFeatureIndexes || [],
    onClick: (clickData) =>
      store.dispatch(
        updateMobOpsLogDistrictSettings({
          id: layerId,
          selectedFeatureIndexes: [clickData.index],
        })
      ),
    onHover: (hoverData) =>
      dispatchTooltipData(layerConfig.mapConfigId, hoverData),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onEdit: ({ updatedData, editType, editContext }) => {
      store.dispatch(
        updateMobOpsLogDistrictData({ id: layerId, geoJson: updatedData })
      );
    },
    _subLayerProps: {
      geojson: getMobOpsLogGeoJsonLayerStyle(layerId),
    },
  });
}

export function createMobOpsLogDistrictDiffGeoJsonLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  const mobOpsLogDistrictDiffSettings: MobOpsLogDistrictDiffSettings | null =
    selectMobOpsLogDistrictDiffSettingsById(store.getState(), layerId);
  const { data } = layerData;
  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];

  let colorAlpha = mobOpsLogDistrictDiffSettings?.colorAlpha;
  if (colorAlpha === undefined) {
    colorAlpha = 100;
  }

  return new DeckGLGeoJsonLayer({
    id: layerId,
    data,
    visible,
    pickable: true,
    onHover: (hoverData: any) =>
      dispatchTooltipData(layerConfig.mapConfigId, hoverData),
    updateTriggers: {
      getFillColor: colorAlpha,
      getLineColor: colorAlpha,
      getColor: colorAlpha,
    },
    transitions: {
      getFillColor: {
        duration: 750,
        enter: (rbg: any) => [rbg[0], rbg[1], rbg[2], 0],
      },
    },
    ...getMobOpsLogGeoJsonDistrictDiffLayerStyle(colorAlpha),
  });
}

export function createFlowLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  let data: FlowData | undefined;
  if (isFlowData(layerData.data)) {
    data = layerData.data as FlowData;
  }
  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];
  const mapConfig = state.mapReducer.mapConfigsById[layerConfig.mapConfigId];
  const layer = new FlowLayer({
    id: layerId,
    pickable: true,
    data,
    viewState: mapConfig.viewState,
  });
  return layer.render(visible);
}

export function createWmsLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  let data: WmsDataId | undefined;
  if (isWmsDataId(layerData.data)) {
    data = layerData.data as WmsDataId;
  }
  const layer = new WmsLayer({
    id: layerId,
    getTileData:
      data === "wms-zensus-gitter" ? getZensusInspireGridWmsTiles : undefined,
  });
  return layer.render(visible);
}

export function createTileLayer(
  usePublicOsmTiles?: boolean
): TileLayer<any, TileLayerProps<any>> {
  const tilesUrl = usePublicOsmTiles ? PUBLIC_OSM_TILES_URL : OWN_OSM_TILES_URL;
  return new TileLayer({
    id: "base-tiles",
    data: tilesUrl,
    maxZoom: 19,
    minZoom: 0,
    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
    tileSize: 256,
    pickable: true,
    visible: true,
  });
}

function handleInductionLoopClick(layerId: LayerId, clickData: unknown): void {
  if (isMarker(clickData)) {
    const marker = clickData as Marker;
    if (marker.dataRef) {
      store.dispatch(
        updateTimeseriesSettings({ id: layerId, dataRef: marker.dataRef })
      );
    }
  }
}

export function createLayer(
  layerId: LayerId,
  layerData: LayerData,
  visible: boolean
): any {
  switch (layerId) {
    case "induction-loops":
      return createMarkerLayer(layerId, layerData, visible, (clickData) =>
        handleInductionLoopClick(layerId, clickData)
      );
    case "traffic-information":
    case "school-stats":
    case "points-of-interest":
      return createMarkerLayer(layerId, layerData, visible);
    case "occupancy-avg":
    case "occupancy-daily":
    case "flow-avg":
    case "flow-daily":
      return createHeatmapLayer(layerId, layerData, visible);
    case "road-network":
    case "bike-network":
    case "city-areas":
    case "zensus-100m":
    case "tazes":
      return createGeoJsonLayer(layerId, layerData, visible);
    case "commuters":
      return createFlowLayer(layerId, layerData, visible);
    case "zensus-1km-wms":
      return createWmsLayer(layerId, layerData, visible);
    case "mobopslog-routes":
    case "mobopslog-routes-line":
    case "mobopslog-clusters":
    case "mobopslog-result":
    case "mobopslog-future-routes":
    case "mobopslog-future-routes-line":
    case "mobopslog-future-clusters":
    case "mobopslog-future-result":
      return createEditableGeoJsonLayer(layerId, layerData, visible);
    case "mobopslog-diff-result":
      return createMobOpsLogDistrictDiffGeoJsonLayer(
        layerId,
        layerData,
        visible
      );
    case "capacity": // TODO: Implement layer for capacities
    default:
      return undefined;
  }
}

export function createRenderedLayers(
  layersById: Partial<ObjectsByLayerId<Layer>>,
  dataById: Partial<ObjectsByLayerId<LayerData>>
): any[] {
  return Object.entries(dataById)
    .filter(([id]) => isLayerId(id))
    .reduce((renderedLayers: any[], [id, data]) => {
      const realLayerId = id as LayerId;
      const visible = layersById[realLayerId]?.selected || false;
      const renderedLayer = createLayer(realLayerId, data, visible);
      renderedLayers.push(renderedLayer);
      return renderedLayers;
    }, []);
}

function dispatchFetchPoiLayerData(layerId: LayerId): void {
  const poiSettings = selectPoiSettingsById(store.getState(), layerId);
  if (poiSettings) {
    store.dispatch(
      fetchMarkerData({ layerId, dataId: poiSettings.mapFeatureKey.id })
    );
  } else {
    store.dispatch(clearPoiData(layerId));
  }
}

function dispatchFetchHeatmapLayerData(
  layerId: LayerId,
  dataset: string,
  attrib: string,
  interval: HeatmapDataInterval
): void {
  const currentDate = new Date();
  let fromMs = getStartOfDayMilliseconds(currentDate);
  let toMs = getEndOfDayMilliseconds(currentDate);

  const state = store.getState();
  const layerConfig = state.layerReducer.layersById[layerId];
  const mapConfig = state.mapReducer.mapConfigsById[layerConfig.mapConfigId];

  const { observation } = mapConfig;
  if (observation) {
    const startDate = isoTimestampToDate(observation.startTimestamp || "");
    const endDate = isoTimestampToDate(observation.endTimestamp || "");
    if (startDate) {
      fromMs = startDate.getTime();
      toMs = fromMs;
    }
    if (endDate) {
      toMs = endDate.getTime();
    }
  }

  store.dispatch(
    fetchHeatmapData({
      layerId,
      dataset,
      attrib,
      interval,
      fromMs,
      toMs,
    })
  );
}

function dispatchFetchFlowData(layerId: LayerId, dataset: string): void {
  // TODO: select flowCount from store
  // TODO: select fromMs from store
  // TODO: select toMs from store
  const flowCount = 25;
  const fromMs = 1577833200000; // 01-01-2020 00:00:00
  const toMs = 1609455599000; // 31-12-2020 23:59:59
  store.dispatch(
    fetchFlowData({
      layerId,
      dataset,
      flowCount,
      fromMs,
      toMs,
    })
  );
}

function dispatchFetchMobOpsLogRouteLayerData(
  layerId: LayerId,
  scenario: string,
  format: string,
  vehicle?: number
): void {
  const mobOpsLogSettings = selectMobOpsLogRouteSettingsById(
    store.getState(),
    layerId
  );
  if (mobOpsLogSettings) {
    const { datasetKey } = mobOpsLogSettings;
    const fileName = `routes-${datasetKey}-${scenario}.geojson`;
    store.dispatch(
      fetchMobOpsLogRoutesData({
        layerId,
        fileName,
        format,
        vehicle,
      })
    );
  } else {
    store.dispatch(clearMobOpsLogRouteData(layerId));
  }
}

function dispatchFetchMobOpsLogDistrictDiffData(layerId: LayerId): void {
  const mobOpsLogDistrictDiffSettings: MobOpsLogDistrictDiffSettings | null =
    selectMobOpsLogDistrictDiffSettingsById(store.getState(), layerId);
  const clusterGroupKey =
    mobOpsLogDistrictDiffSettings?.clusterGroupKey || "clusters-now";
  let timerId = mobOpsLogDistrictDiffSettings?.timerId || null;
  store
    .dispatch(
      fetchMobOpsLogDistrictData({
        layerId,
        clusterGroupKey,
      })
    )
    .then(() => {
      store.dispatch(
        updateMobOpsLogDistrictDiffColorAlpha({
          id: layerId,
          colorAlpha: 150,
        })
      );
    });

  if (timerId == null) {
    timerId = window.setInterval(() => {
      setTimeout(() => {
        store.dispatch(
          updateMobOpsLogDistrictDiffColorAlpha({
            id: layerId,
            colorAlpha: 125,
          })
        );
        setTimeout(() => {
          store.dispatch(
            updateMobOpsLogDistrictDiffColorAlpha({
              id: layerId,
              colorAlpha: 100,
            })
          );
          setTimeout(() => {
            store.dispatch(
              updateMobOpsLogDistrictDiffColorAlpha({
                id: layerId,
                colorAlpha: 75,
              })
            );
            setTimeout(() => {
              store.dispatch(
                updateMobOpsLogDistrictDiffColorAlpha({
                  id: layerId,
                  colorAlpha: 50,
                })
              );
              setTimeout(() => {
                store.dispatch(
                  updateMobOpsLogDistrictDiffColorAlpha({
                    id: layerId,
                    colorAlpha: 25,
                  })
                );
                setTimeout(() => {
                  store.dispatch(
                    updateMobOpsLogDistrictDiffColorAlpha({
                      id: layerId,
                      colorAlpha: 0,
                    })
                  );
                  setTimeout(() => {
                    dispatchFetchMobOpsLogDistrictDiffData(layerId);
                  }, 300);
                }, 100);
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 3000);
  }
  store.dispatch(
    updateMobOpsLogDistrictDiffSettings({
      id: layerId,
      clusterGroupKey:
        clusterGroupKey === "clusters-now" ? "clusters-2030" : "clusters-now",
      colorAlpha: 255,
      timerId,
    })
  );
}

export function dispatchFetchLayerData(
  layerId: LayerId,
  forceReload?: boolean
): void {
  const layerState = store.getState().layerReducer;
  const layer = layerState.layersById[layerId];
  const layerStatus = layerState.statusesById[layerId];
  const layerDataFetched = layerStatus.status === "succeeded";
  if ((!layerDataFetched || forceReload) && layer.selected) {
    Logger.debug(`Fetching data for layer '${layerId}'`);
    switch (layerId) {
      case "induction-loops":
        store.dispatch(fetchMarkerData({ layerId, dataId: "mdm-traffic" }));
        break;
      case "traffic-information":
        store.dispatch(fetchMarkerData({ layerId, dataId: "mdm-incidents" }));
        break;
      case "school-stats":
        store.dispatch(fetchMarkerData({ layerId, dataId: "ffm-schools" }));
        break;
      case "points-of-interest":
        dispatchFetchPoiLayerData(layerId);
        break;
      case "occupancy-avg":
        dispatchFetchHeatmapLayerData(
          layerId,
          "mdm-traffic",
          "occupancy",
          "daily"
        );
        break;
      case "occupancy-daily":
        dispatchFetchHeatmapLayerData(
          layerId,
          "mdm-traffic",
          "occupancy",
          "half-hourly"
        );
        break;
      case "flow-avg":
        dispatchFetchHeatmapLayerData(
          layerId,
          "mdm-traffic",
          "vehicle_flow_rate",
          "daily"
        );
        break;
      case "flow-daily":
        dispatchFetchHeatmapLayerData(
          layerId,
          "mdm-traffic",
          "vehicle_flow_rate",
          "half-hourly"
        );
        break;
      case "road-network":
        store.dispatch(fetchGeoJsonData({ layerId, dataCategoryId: "roads" }));
        break;
      case "bike-network":
        store.dispatch(
          fetchGeoJsonData({ layerId, dataCategoryId: "cycle_lanes" })
        );
        break;
      case "city-areas":
        store.dispatch(
          fetchGeoJsonData({ layerId, dataCategoryId: "community_boundaries" })
        );
        break;
      case "zensus-100m":
        store.dispatch(
          fetchGeoJsonData({ layerId, dataCategoryId: "zensus_grid_100m" })
        );
        break;
      case "tazes":
        store.dispatch(fetchTazesGeoJsonData({ layerId }));
        break;
      case "commuters":
        dispatchFetchFlowData(layerId, "ba-commuters");
        break;
      case "mobopslog-routes":
        dispatchFetchMobOpsLogRouteLayerData(layerId, "now", "multipoint");
        break;
      case "mobopslog-routes-line":
        dispatchFetchMobOpsLogRouteLayerData(layerId, "now", "linestring", 0);
        break;
      case "mobopslog-clusters":
        store.dispatch(
          fetchMobOpsLogClusterData({
            layerId,
            groupKey: "clusters-now",
          })
        );
        break;
      case "mobopslog-result":
        store.dispatch(
          fetchMobOpsLogDistrictData({
            layerId,
            clusterGroupKey: "clusters-now",
          })
        );
        break;
      case "mobopslog-future-routes":
        dispatchFetchMobOpsLogRouteLayerData(layerId, "2030", "multipoint");
        break;
      case "mobopslog-future-routes-line":
        dispatchFetchMobOpsLogRouteLayerData(layerId, "2030", "linestring", 0);
        break;
      case "mobopslog-future-clusters":
        store.dispatch(
          fetchMobOpsLogClusterData({
            layerId,
            groupKey: "clusters-2030",
          })
        );
        break;
      case "mobopslog-future-result":
        store.dispatch(
          fetchMobOpsLogDistrictData({
            layerId,
            clusterGroupKey: "clusters-2030",
          })
        );
        break;
      case "mobopslog-diff-result":
        dispatchFetchMobOpsLogDistrictDiffData(layerId);
        break;
      case "zensus-1km-wms":
      case "capacity": // TODO: implement fetching capacity layer data
      default:
        Logger.warn("Data fetching not implemented for layer with id", layerId);
        break;
    }
  }
}
