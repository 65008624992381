import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";

export default function WelcomePage(): React.ReactElement {
  const navigate = useNavigate();

  const isMediaSizeSmallerMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleShowcaseClicked = (): void => {
    navigate("/map");
  };

  const handleCreateClicked = (): void => {
    navigate("/bbox-selection");
  };

  const handleLoginClicked = (): void => {
    navigate("/login");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.grey[800],
      }}
    >
      <Box
        sx={{
          mt: (theme) => theme.spacing(16),
          width: "80%",
          maxWidth: 600,
          height: "auto",
        }}
        component="img"
        src="/strazoon-dark-100.png"
        alt="strazoon"
      />
      <Box
        sx={{
          py: (theme) => theme.spacing(8),
          flexGrow: 1,
          width: "80%",
          maxWidth: 850,
          color: (theme) => theme.palette.wht.main,
          backgroundColor: (theme) => theme.palette.grey[800],
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography component="p" variant="body1">
          Mit dem Geo Digital Twin lassen sich Verkehrsmodelle automatisiert
          erstellen und pflegen. Einfach. Per Click. Und gemäß der Daten und
          Automatisierungsregeln Ihrer Wahl.
        </Typography>
        <Typography
          sx={{ mt: (theme) => theme.spacing(3) }}
          component="p"
          variant="body1"
        >
          Zukünftige Änderungen im Modell lassen sich vorplanen und fließen
          zeitpunktspezifisch in den Master ein.
        </Typography>
        <Typography
          sx={{ mt: (theme) => theme.spacing(3) }}
          component="p"
          variant="body1"
        >
          Wollen Sie das Verkehrsmodell in anderer Software zur
          Weiterverarbeitung nutzen? Erstellen Sie einen Export für einen
          beliebigen Zeitpunkt und ein beliebiges Szenario Ihrer Wahl.
        </Typography>
        <Typography
          sx={{ mt: (theme) => theme.spacing(3) }}
          component="p"
          variant="body1"
        >
          Probieren Sie es aus.
        </Typography>
      </Box>
      <Grid sx={{ mb: (theme) => theme.spacing(8) }} container spacing={3}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: isMediaSizeSmallerMd ? "center" : "flex-end",
          }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="contained"
            color="primary"
            onClick={handleShowcaseClicked}
          >
            Showcase
          </Button>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="contained"
            color="primary"
            onClick={handleCreateClicked}
          >
            Verkehrsmodell erstellen
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: isMediaSizeSmallerMd ? "center" : "flex-start",
          }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="contained"
            color="primary"
            onClick={handleLoginClicked}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
