/* eslint-disable @typescript-eslint/no-explicit-any */
import { ViewStateProps } from "@deck.gl/core/lib/deck";
import * as Cluster from "@flowmap.gl/cluster";
import FlowMapLayer, {
  Location,
  Flow,
  FlowLayerPickingInfo,
  PickingType,
  isFeatureCollection,
} from "@flowmap.gl/core";
import { FlowData, FlowValue, LocationValue } from "../../../api/layers/types";
import { Highlight, HighlightType } from "./types";

interface FlowLayerProps {
  id: string;
  pickable: boolean;
  data?: FlowData;
  viewState?: ViewStateProps;
}

const FLOW_SHOW_TOP_COUNT = 10000;
const FLOW_MULTISELECT = true;

const EMPTY_DATA: FlowData = {
  locations: [],
  flows: [],
};

export default class FlowLayer {
  private props: FlowLayerProps;

  private highlight?: Highlight;

  private selectedLocationIds?: string[];

  private clusterIndex?: Cluster.ClusterIndex;

  private aggregateFlowsByZoom?: Map<number, Flow[]>;

  private clusteredLocations?: Location[];

  private aggregateFlows?: Flow[];

  constructor(props: FlowLayerProps) {
    this.props = props;
    this.updateLayerData(props.data || EMPTY_DATA);
  }

  private getLocationId = (location: LocationValue[]): string =>
    String(location[0]);

  private getLocationCentroid = (
    location: LocationValue[]
  ): [number, number] => [
    Number(location[2]), // lon
    Number(location[1]), // lat
  ];

  private getFlowOriginId = (flow: FlowValue[]): string => String(flow[0]);

  private getFlowDestId = (flow: FlowValue[]): string => String(flow[1]);

  private getFlowMagnitude = (flow: FlowValue[]): number => Number(flow[2]);

  private getAggregateFlowOriginId = (flow: Flow): string =>
    String(flow.origin);

  private getAggregateFlowDestId = (flow: Flow): string => String(flow.dest);

  private getAggregateFlowCount = (flow: Flow): number => Number(flow.count);

  private handleFlowMapHover = (info: FlowLayerPickingInfo): void => {
    const { type, object } = info;
    switch (type) {
      case PickingType.FLOW: {
        if (!object) {
          this.highlight = undefined;
        } else {
          this.highlight = {
            type: HighlightType.FLOW,
            flow: object as any,
          };
        }
        break;
      }
      case PickingType.LOCATION_AREA:
      case PickingType.LOCATION: {
        if (!object) {
          this.highlight = undefined;
        } else {
          this.highlight = {
            type:
              type === PickingType.LOCATION_AREA
                ? HighlightType.LOCATION_AREA
                : HighlightType.LOCATION,
            locationId: (
              this.getLocationId ||
              FlowMapLayer.defaultProps.getLocationId.value
            )(object),
          };
        }
        break;
      }
      default:
        break;
    }
  };

  private handleFlowMapClicked = (info: FlowLayerPickingInfo): void => {
    const { type, object } = info;
    switch (type) {
      case PickingType.LOCATION_AREA:
      case PickingType.LOCATION: {
        if (object) {
          const locationId = (
            this.getLocationId || FlowMapLayer.defaultProps.getLocationId.value
          )(object);
          const isSelected =
            this.selectedLocationIds &&
            this.selectedLocationIds.indexOf(locationId) >= 0;
          let next: string[] | undefined;
          if (FLOW_MULTISELECT) {
            if (this.selectedLocationIds) {
              if (isSelected) {
                next = this.selectedLocationIds.filter(
                  (id: any) => id !== locationId
                );
              } else {
                next = [...this.selectedLocationIds, locationId];
              }
            } else {
              next = [locationId];
            }
          } else if (isSelected) {
            next = undefined;
          } else {
            next = [locationId];
          }
          this.selectedLocationIds = next;
          this.highlight = undefined;
        }
        break;
      }
      default:
        break;
    }
  };

  private updateLayerData = (data: FlowData): void => {
    const currentLocations = isFeatureCollection(data.locations)
      ? data.locations.features
      : data.locations;

    const getLocationWeight = Cluster.makeLocationWeightGetter(data.flows, {
      getFlowOriginId: this.getFlowOriginId,
      getFlowDestId: this.getFlowDestId,
      getFlowMagnitude: this.getFlowMagnitude,
    });

    const clusterLevels = Cluster.clusterLocations(
      currentLocations,
      {
        getLocationId: this.getLocationId,
        getLocationCentroid: this.getLocationCentroid,
      },
      getLocationWeight,
      {
        makeClusterName: (id: number, numPoints: number) =>
          `Cluster #${id} of ${numPoints} locations`,
      }
    );

    const currentClusterIndex = Cluster.buildIndex(clusterLevels);
    const currentAggregateFlowsByZoom = new Map<number, FlowValue[][]>();

    // eslint-disable-next-line no-restricted-syntax
    for (const zoom of currentClusterIndex.availableZoomLevels) {
      currentAggregateFlowsByZoom.set(
        zoom,
        currentClusterIndex.aggregateFlows(data.flows, zoom, {
          getFlowOriginId: this.getFlowOriginId,
          getFlowDestId: this.getFlowDestId,
          getFlowMagnitude: this.getFlowMagnitude,
        })
      );
    }

    const maxZoom = Math.max.apply(
      null,
      currentClusterIndex.availableZoomLevels
    );
    this.clusterIndex = currentClusterIndex;
    this.aggregateFlowsByZoom = currentAggregateFlowsByZoom;
    this.clusteredLocations = this.clusterIndex.getClusterNodesFor(maxZoom);
    this.aggregateFlows = this.aggregateFlowsByZoom.get(maxZoom);
    this.updateDataClusters();
  };

  private updateDataClusters = (): void => {
    const { viewState } = this.props;
    if (viewState && this.clusterIndex && this.aggregateFlowsByZoom) {
      const { availableZoomLevels } = this.clusterIndex;
      const { zoom } = viewState;
      const clusterZoom = Cluster.findAppropriateZoomLevel(
        availableZoomLevels,
        zoom || 0
      );
      this.clusteredLocations =
        this.clusterIndex.getClusterNodesFor(clusterZoom);
      this.aggregateFlows = this.aggregateFlowsByZoom.get(clusterZoom);
    }
  };

  render = (visible?: boolean): FlowMapLayer => {
    const { id, pickable } = this.props;

    return new FlowMapLayer({
      id,
      locations: this.clusteredLocations || [],
      flows: this.aggregateFlows || [],
      visible: visible || false,
      pickable,
      showOnlyTopFlows: FLOW_SHOW_TOP_COUNT,
      maxFlowThickness: 15,
      maxLocationCircleSize: 25,
      getFlowOriginId: (flow: any) =>
        Cluster.isAggregateFlow(flow)
          ? this.getAggregateFlowOriginId(flow)
          : this.getFlowOriginId(flow),
      getFlowDestId: (flow: any) =>
        Cluster.isAggregateFlow(flow)
          ? this.getAggregateFlowDestId(flow)
          : this.getFlowDestId(flow),
      getFlowMagnitude: (flow: any) =>
        Cluster.isAggregateFlow(flow)
          ? this.getAggregateFlowCount(flow)
          : this.getFlowMagnitude(flow),
      getLocationId: (location: Cluster.ClusterNode) =>
        location ? location.id : "",
      getLocationCentroid: (location: Cluster.ClusterNode) =>
        location ? location.centroid : [0, 0],
      selectedLocationIds: this.selectedLocationIds,
      highlightedLocationId:
        this.highlight && this.highlight.type === HighlightType.LOCATION
          ? this.highlight.locationId
          : undefined,
      highlightedLocationAreaId:
        this.highlight && this.highlight.type === HighlightType.LOCATION_AREA
          ? this.highlight.locationId
          : undefined,
      highlightedFlow:
        this.highlight && this.highlight.type === HighlightType.FLOW
          ? this.highlight.flow
          : undefined,
      onHover: this.handleFlowMapHover,
      // onClick: this.handleFlowMapClicked,
    });
  };
}
