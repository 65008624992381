/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any usages

import { MapConfig } from "./types";

function equalMapConfigId(
  prevConfig: any,
  nextConfig: any
): boolean {
  return prevConfig.id === nextConfig.id;
}

export function equalMapConfigStyle(
  prevConfig: any,
  nextConfig: any
): boolean {
  return (
    equalMapConfigId(prevConfig, nextConfig) &&
    prevConfig.style === nextConfig.style
  );
}

export function equalMapConfigLocation(
  prevConfig: any,
  nextConfig: any
): boolean {
  return (
    equalMapConfigId(prevConfig, nextConfig) &&
    prevConfig.viewState.latitude === nextConfig.viewState.latitude &&
    prevConfig.viewState.longitude === nextConfig.viewState.longitude
  );
}

export function equalMapConfigZoom(
  prevConfig: any,
  nextConfig: any
): boolean {
  return (
    equalMapConfigId(prevConfig, nextConfig) &&
    prevConfig.viewState.zoom === nextConfig.viewState.zoom
  );
}

export function equalMapConfigObservation(
  prevConfig: any,
  nextConfig: any
): boolean {
  return (
    equalMapConfigId(prevConfig, nextConfig) &&
    !!prevConfig.observation &&
    !!nextConfig.observation &&
    prevConfig.observation.startTimestamp ===
      nextConfig.observation.startTimestamp &&
    prevConfig.observation.endTimestamp === nextConfig.observation.endTimestamp
  );
}

export function isMapCentered(mapConfig: MapConfig): boolean {
  const { viewState, defaultViewState } = mapConfig;
  return (
    viewState.latitude === defaultViewState.latitude &&
    viewState.longitude === defaultViewState.longitude
  );
}

export function calculateZoomPercentage(mapConfig: MapConfig): number {
  const { viewState, defaultViewState } = mapConfig;
  const { zoom } = viewState;
  const defaultZoom = defaultViewState.zoom;
  const relativeZoomLevel = zoom / defaultZoom;
  const zoomPercentage = relativeZoomLevel * 100;
  return Math.round(zoomPercentage);
}
