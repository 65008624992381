import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { LayerId } from "../../../../store/slices/layers/types";
import ControllerBase from "./ControllerBase";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectMobOpsLogRouteSettingsById } from "../../../../store/slices/layers/selectors";
import { dispatchFetchLayerData } from "../../../../store/slices/layers/factory";
import {  updateMobOpsLogRouteSettings } from "../../../../store/slices/layers/slice";

interface DeliveryControllerProps {
  layerId: LayerId;
}

type Option = { key: string; name: string };

const options: Option[] = [
  { key: "it0", name: "Datensatz 1" },
  { key: "it1", name: "Datensatz 2" },
  { key: "it2", name: "Datensatz 3" },
  { key: "it3", name: "Datensatz 4" },
  { key: "it4", name: "Datensatz 5" },
];

function getOptionByKey(key: string) {
  return options.find((option) => option.key === key);
}

export default function DeliveryController({
  layerId,
}: DeliveryControllerProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const mobOpsLogSettings = useAppSelector((state) =>
      selectMobOpsLogRouteSettingsById(state, layerId)
  );

  const handleChangeDatasetKey = (
    event: React.SyntheticEvent<Element, Event>,
    value: Option | null
  ): void => {
    dispatch(
        updateMobOpsLogRouteSettings({ id: layerId, datasetKey: value?.key || null })
    );
    dispatchFetchLayerData(layerId, true);
  };

  const currentOption = mobOpsLogSettings?.datasetKey
    ? getOptionByKey(mobOpsLogSettings?.datasetKey)
    : options[0];

  return (
    <ControllerBase sx={{ width: 200 }} label="Routendatensätze">
      <Autocomplete
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
        disableClearable
        options={options}
        getOptionLabel={(option) => option.name}
        autoHighlight
        value={currentOption}
        onChange={handleChangeDatasetKey}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label="Auswahl des Datensatzes"
            variant="outlined"
          />
        )}
      />
    </ControllerBase>
  );
}
