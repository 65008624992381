import React, { useState } from "react";
import { SxProps, Theme } from "@mui/material";
import { TestConfigId } from "../../../../store/slices/maps/types";
import CircleButton from "../../../custom/buttons/CircleButton";
import { CommuterIcon } from "../../../icons/CommuterIcon";
import DeliveryControllerLayoutDialog
  from "../../../dialog/DeliveryControlsLayoutDialog/DeliveryControllerLayoutDialog";

interface DeliveryControlsProps {
  sx?: SxProps<Theme>;
  testConfigId: TestConfigId;
}

type DialogId = "option";

export default function DeliveryControls({
  sx,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  testConfigId,
}: DeliveryControlsProps): React.ReactElement {
  const [openedDialogId, setOpenedDialogId] = useState<DialogId | null>(null);

  const handleOpenDialog = (event: React.MouseEvent, id: DialogId): void => {
    event.preventDefault();
    event.stopPropagation();
    setOpenedDialogId(id);
  };

  const handleCloseDialog = (event: React.MouseEvent, id: DialogId): void => {
    event.preventDefault();
    event.stopPropagation();
    if (openedDialogId === id) {
      setOpenedDialogId(null);
    }
  };

  return (
    <>
      <CircleButton
        sx={sx}
        size={40}
        label={<CommuterIcon />}
        color="white"
        onClick={(event) => handleOpenDialog(event, "option")}
      />

      <DeliveryControllerLayoutDialog
          testConfigId={testConfigId}
        open={openedDialogId === "option"}
        onClose={(event) => handleCloseDialog(event, "option")}
      />
    </>
  );
}

DeliveryControls.defaultProps = {
  sx: undefined,
};
