/* eslint-disable @typescript-eslint/no-explicit-any */
import { PickInfo } from "deck.gl";
import { RGBAColor } from "@deck.gl/core";
import {
  GeoJsonLayer as DeckGLGeoJsonLayer,
  GeoJsonLayerProps as DeckGLGeoJsonLayerProps,
} from "@deck.gl/layers";
import { GeoJsonData, GeoJsonLayerProperty } from "../../../api/layers/types";

interface GeoJsonLayerProps {
  id: string;
  data?: any;
  allowObjectSelection?: boolean;
  onHover?: (hoverData: PickInfo<any>) => void;
  onClick?: (clickData: PickInfo<any>) => void;
}

interface SelectedObjectsById {
  [id: string]: any;
}

const defaultLayerProperties: GeoJsonLayerProperty[] = [
  {
    id: "",
    dataCategoryId: "",
    dataSubCategoryId: "",
    description: "",
    lineWidth: 1,
    lineColor: [0, 0, 0],
    lineOpacity: 0.8,
    fillColor: [253, 141, 60],
    fillOpacity: 0.4,
  },
];

export default class GeoJsonLayer {
  private readonly props;

  private renderData?: GeoJsonData;

  private selectedObjectsById: SelectedObjectsById;

  constructor(props: GeoJsonLayerProps) {
    this.props = props;
    this.renderData = props.data;
    this.selectedObjectsById = {};
  }

  clearSelectedObjects = (): void => {
    this.selectedObjectsById = {};
  };

  getSelectedObjects = (): SelectedObjectsById => this.selectedObjectsById;

  private handleSelectObjectByClick = (clickData: PickInfo<any>): void => {
    const { object } = clickData;
    if (object?.geometry && object?.properties?.id) {
      const id = String(object.properties.id);
      if (this.selectedObjectsById[id]) {
        delete this.selectedObjectsById[id];
      } else {
        this.selectedObjectsById[id] = object;
      }
    }
  };

  private getLayerPropertyForObject = (
    object: any,
    properties: GeoJsonLayerProperty[]
  ): GeoJsonLayerProperty => {
    if (properties.length === 0) {
      return defaultLayerProperties[0];
    }
    if (properties[0].dataCategoryId === "roads") {
      return this.findLayerPropertyForObject(object, properties);
    }
    if (properties[0].dataCategoryId === "areas") {
      return this.findLayerPropertyForObject(object, properties);
    }
    return properties[0];
  };

  private findLayerPropertyForObject = (
    object: any,
    properties: GeoJsonLayerProperty[]
  ): GeoJsonLayerProperty => {
    const objProperty = properties.find(
      (property) =>
        property.id === object?.properties?.tags?.value ||
        property.id === object?.properties?.tags?.category
    );
    if (!objProperty) {
      return defaultLayerProperties[0];
    }
    return objProperty;
  };

  private getFillColor = (
    object: any,
    properties: GeoJsonLayerProperty[]
  ): RGBAColor => {
    const objProperty = this.getLayerPropertyForObject(object, properties);
    const color = objProperty.fillColor;
    const alpha = color[3] || 255 * objProperty.fillOpacity;
    const id = object?.properties?.id;
    if (id && this.selectedObjectsById[id]) {
      const newAlpha = Math.min(alpha * 2, 255);
      return [color[0], color[1], color[2], newAlpha];
    }
    return [color[0], color[1], color[2], alpha];
  };

  private getLineColor = (
    object: any,
    properties: GeoJsonLayerProperty[]
  ): RGBAColor => {
    const objProperty = this.getLayerPropertyForObject(object, properties);
    const color = objProperty.lineColor;
    const alpha = color[3] || 255 * objProperty.lineOpacity;
    const id = object?.properties?.id;
    if (id && this.selectedObjectsById[id]) {
      const newAlpha = Math.min(alpha * 3, 255);
      return [color[0], color[1], color[2], newAlpha];
    }
    return [color[0], color[1], color[2], alpha];
  };

  private getLineWidth = (
    object: any,
    properties: GeoJsonLayerProperty[]
  ): number => {
    const objProperty = this.getLayerPropertyForObject(object, properties);
    return objProperty.lineWidth;
  };

  render = (
    visible?: boolean
  ): DeckGLGeoJsonLayer<any, DeckGLGeoJsonLayerProps<any>> => {
    const { id, allowObjectSelection, onHover, onClick } = this.props;
    const data = this.renderData?.geoJson || [];
    let properties = this.renderData?.properties || defaultLayerProperties;
    if (!properties.length) {
      properties = defaultLayerProperties;
    }

    let stroked = true;
    let filled = true;
    const extruded = false;
    const wireframe = false;

    if (properties[0].dataCategoryId === "roads") {
      stroked = false;
      filled = false;
    }

    return new DeckGLGeoJsonLayer({
      id,
      data,
      visible: visible || false,
      opacity: 1.0,
      stroked,
      filled,
      extruded,
      wireframe,
      getFillColor: (object: any) => this.getFillColor(object, properties),
      getLineColor: (object: any) => this.getLineColor(object, properties),
      getLineWidth: (object: any) => this.getLineWidth(object, properties),
      pickable: true,
      onHover,
      onClick: (clickData: PickInfo<any>) => {
        if (allowObjectSelection) {
          this.handleSelectObjectByClick(clickData);
        }
        if (onClick) {
          onClick(clickData);
        }
      },
      updateTriggers: {
        getFillColor: Object.keys(this.selectedObjectsById).length,
        getLineColor: Object.keys(this.selectedObjectsById).length,
      },
    });
  };
}
