import { Paper, Typography } from "@mui/material";
import { formatTimestampToTime } from "../../../tech/utils/TimestampFormatter";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function CustomTooltipContent({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ px: 2, py: 1 }} elevation={2}>
        <Typography component="p" variant="body1" sx={{ fontWeight: 500 }}>
          {`${formatTimestampToTime(label)} Uhr`}
        </Typography>
        {payload[0] && (
          <Typography
            component="p"
            variant="body1"
          >{`${payload[0].name}: ${payload[0].value}`}</Typography>
        )}
      </Paper>
    );
  }

  return null;
}
