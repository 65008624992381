/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { MapFeatureKey } from "../../../api/map-feature-keys/types";
import type { RootState } from "../../store";
import { fetchMapFeatureKeys } from "./thunks";
import { MapFeatureKeyState } from "./types";

const initialState: MapFeatureKeyState = {
  mapFeatureKeysById: {},
  mapFeatureKeyStatus: { status: "idle" },
};

export const mapFeatureKeySlice = createSlice({
  name: "mapFeatureKeys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMapFeatureKeys.pending, (state) => {
      state.mapFeatureKeyStatus.status = "loading";
    });
    builder.addCase(fetchMapFeatureKeys.fulfilled, (state, action) => {
      action.payload.forEach((mapFeatureKey) => {
        state.mapFeatureKeysById[mapFeatureKey.id] = mapFeatureKey;
      });
      state.mapFeatureKeyStatus.status = "succeeded";
    });
    builder.addCase(fetchMapFeatureKeys.rejected, (state, action) => {
      state.mapFeatureKeyStatus = {
        status: "failed",
        error: action.error.message,
      };
    });
  },
});

export const selectAllMapFeatureKeys = (state: RootState): MapFeatureKey[] =>
  Object.values(state.mapFeatureKeyReducer.mapFeatureKeysById);

export default mapFeatureKeySlice.reducer;
