import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { MapConfigId } from "../../../store/slices/maps/types";
import Logger from "../../../tech/utils/Logger";
import { BaseDialog } from "../base/BaseDialog";
import LoadMapLayoutContent from "./LoadMapLayoutContent";
import { MapLayout } from "./types";

interface LoadMapLayoutDialogProps {
  mapConfigId: MapConfigId;
  open: boolean;
  onClose: React.MouseEventHandler;
}

// TODO: get data from backend
const standardMockData: MapLayout[] = [
  { id: "layout-1", label: "Ansicht für Radfahrer" },
  { id: "layout-2", label: "Ansicht mit Fokus auf Kapazität" },
  { id: "layout-3", label: "Ansicht mit Fokus auf Pendelverkehr" },
];

// TODO: get data from backend
const myMockData: MapLayout[] = [
  { id: "layout-1", label: "Ansicht 1 (01.01.2022 - 10:01)", public: true },
  { id: "layout-2", label: "Ansicht 2 (31.01.2022 - 17:44)" },
  { id: "layout-3", label: "Ansicht 3 (01.02.2022 - 13:30)", public: true },
];

// TODO: get data from backend
const publicMockData: MapLayout[] = [
  {
    id: "layout-1",
    label: "Ansicht von Martin (Reuss-Lächele, Martin)",
    public: true,
  },
  {
    id: "layout-2",
    label: "Ansicht von Rikardo (Marenzzi, Rikardo)",
    public: true,
  },
  {
    id: "layout-3",
    label: "Ansicht von Tobias (Lukowitz, Tobias)",
    public: true,
  },
];

export default function LoadMapLayoutDialog({
  mapConfigId,
  open,
  onClose,
}: LoadMapLayoutDialogProps): React.ReactElement {
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentTabIdx(newValue);
  };


  const handleLoadLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", mapConfigId);
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Ansicht laden?"
      primaryButton={{
        label: "Ansicht laden",
        onClick: handleLoadLayout,
      }}
    >
      <Tabs
        sx={{
          mx: (theme) => theme.spacing(-3),
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
        value={currentTabIdx}
        onChange={handleChangeTab}
      >
        <Tab sx={{ flexBasis: "33%" }} label="Standard Ansichten" />
        <Tab sx={{ flexGrow: 1 }} label="Meine Ansichten" />
        <Tab sx={{ flexBasis: "33%" }} label="Freigegebene Ansichten" />
      </Tabs>
      <Box
        sx={{
          minHeight: 170,
          pt: (theme) => theme.spacing(3),
          pl: (theme) => theme.spacing(4),
        }}
      >
        {currentTabIdx === 0 && (
          <LoadMapLayoutContent entries={standardMockData} />
        )}
        {currentTabIdx === 1 && (
          <LoadMapLayoutContent
            entries={myMockData}
            allowShare
            allowEdit
            allowDelete
          />
        )}
        {currentTabIdx === 2 && (
          <LoadMapLayoutContent entries={publicMockData} />
        )}
      </Box>
    </BaseDialog>
  );
}
