import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { de } from "date-fns/locale";
import ApiContextProvider from "./tech/context/ApiContextProvider";
import BackdropContextProvider from "./tech/context/BackdropContextProvider";
import UmpfBackdrop from "./components/custom/BackdropSpinner";
import LoginPage from "./pages/onboarding/LoginPage";
import MapPage from "./pages/MapPage";
import VersionsBoardPage from "./pages/versioning/VersionsBoardPage";
import RulesManagerPage from "./pages/RulesManagerPage";
import ChangeListPage from "./pages/versioning/ChangeListPage";
import ChangeHistoryPage from "./pages/versioning/ChangeHistoryPage";
import ChangeAddPage from "./pages/versioning/ChangeAddPage";
import ChangeDetailsPage from "./pages/versioning/ChangeDetailsPage";
import ExportPage from "./pages/versioning/ExportPage";
import SettingsPage from "./pages/SettingsPage";
import AGBPage from "./pages/legal/AGBPage";
import LicensesPage from "./pages/legal/LicensesPage";
import AreaSelectionPage from "./pages/onboarding/AreaSelectionPage";
import WelcomePage from "./pages/onboarding/WelcomePage";
import RoleBasedRoute from "./components/access-control/RoleBasedRoute";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { fetchMapFeatureKeys } from "./store/slices/map-feature-keys/thunks";
import FaqPage from "./pages/FaqPage";
import ContactPage from "./pages/ContactPage";
import InfoPage from "./pages/InfoPage";
import StyleGuidePage from "./pages/StyleGuide";

registerLocale("de", de);
setDefaultLocale("de");

export default function App(): React.ReactElement {
  const dispatch = useAppDispatch();
  const mapFeatureKeyStatus = useAppSelector(
    (state) => state.mapFeatureKeyReducer.mapFeatureKeyStatus.status
  );

  useEffect(() => {
    if (mapFeatureKeyStatus === "idle") {
      dispatch(fetchMapFeatureKeys());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFeatureKeyStatus]);

  return (
    <ApiContextProvider>
      <BackdropContextProvider>
        <CssBaseline />
        <Routes>
          <Route
            path="/welcome"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <WelcomePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <LoginPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/bbox-selection"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <AreaSelectionPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/map"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <MapPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/versions-board"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <VersionsBoardPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/rules"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <RulesManagerPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/changes"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <ChangeListPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/changes/history"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <ChangeHistoryPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/changes/new"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <ChangeAddPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/changes/:id"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <ChangeDetailsPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/export"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <ExportPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <RoleBasedRoute roles={["USER"]}>
                <SettingsPage />
              </RoleBasedRoute>
            }
          />
          <Route path="/agb" element={<AGBPage />} />
          <Route path="/licenses" element={<LicensesPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/style-guide" element={<StyleGuidePage />} />
          <Route path="*" element={<Navigate to="/welcome" />} />
        </Routes>
        <UmpfBackdrop />
      </BackdropContextProvider>
    </ApiContextProvider>
  );
}
