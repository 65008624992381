import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectAllDebugLayerCategories,
  toggleDebugCategoryVisibilty,
} from "../../../store/slices/layer-categories/slice";
import CircleButton from "../../custom/buttons/CircleButton";
import LayerSubMenu from "./LayerSubMenu";

export default function DebugLayerSubMenu(): React.ReactElement {
  const dispatch = useAppDispatch();
  const debugCategories = useAppSelector((state) =>
    selectAllDebugLayerCategories(state)
  );

  const handleClickDebugMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleDebugCategoryVisibilty());
  };

  return (
    <>
      {debugCategories
        .filter((debugCategory) => debugCategory.visible)
        .map((debugCategory) => (
          <LayerSubMenu key={debugCategory.id} categoryId={debugCategory.id} />
        ))}
      <CircleButton
        onClick={handleClickDebugMenuToggle}
        label={debugCategories[0].visible ? "-" : "+"}
        color="white"
      />
    </>
  );
}
