import {
  FlowData,
  GeoJsonData,
  HeatmapData,
  Marker,
  MarkerData,
  WmsDataId,
  wmsDataIds,
} from "../../../api/layers/types";
import {
  HeatmapSettings,
  PoiSettings,
  TimeseriesSettings,
  DeliverySettings,
  MobOpsLogDistrictSettings,
  MobOpsLogRouteSettings,
  MobOpsLogDistrictDiffSettings,
} from "../../../components/map/layer/types";
import { LayerId, layerIds, LayerSettings } from "./types";

export function isLayerId(value: string): value is LayerId {
  return layerIds.includes(value as LayerId);
}

export function isMarker(data: unknown): data is Marker {
  const potentialMarker = data as Marker;
  return (
    !!potentialMarker?.id && !!potentialMarker?.lat && !!potentialMarker?.lon
  );
}

export function isMarkerData(data: unknown): data is MarkerData {
  return !!(data as MarkerData)?.markers;
}

export function isHeatmapData(data: unknown): data is HeatmapData {
  return !!(data as HeatmapData)?.heatmaps;
}

export function isHeatmapSettings(
  settings: LayerSettings | null
): settings is HeatmapSettings {
  if (!settings) {
    return false;
  }
  const potentialHeatmapSettings = settings as HeatmapSettings;
  return (
    !!potentialHeatmapSettings?.intensity &&
    !!potentialHeatmapSettings?.radiusPixels &&
    !!potentialHeatmapSettings?.threshold
  );
}

export function isPoiSettings(
  settings: LayerSettings | null
): settings is PoiSettings {
  if (!settings) {
    return false;
  }
  const potentialPoiSettings = settings as PoiSettings;
  return !!potentialPoiSettings?.mapFeatureKey;
}

export function isDeliverySettings(
  settings: LayerSettings | null
): settings is DeliverySettings {
  if (!settings) {
    return false;
  }
  const potentialDeliverySettings = settings as DeliverySettings;
  return !!potentialDeliverySettings?.mapFeatureKey;
}

export function isTimeseriesSettings(
  settings: LayerSettings | null
): settings is TimeseriesSettings {
  if (!settings) {
    return false;
  }
  const potentialTimeseriesSettings = settings as TimeseriesSettings;
  return !!potentialTimeseriesSettings?.dataRef;
}

export function isMobOpsLogRouteSettings(
  settings: LayerSettings | null
): settings is MobOpsLogRouteSettings {
  if (!settings) {
    return false;
  }
  const potentialMobOpsLogSettings = settings as MobOpsLogRouteSettings;
  return !!potentialMobOpsLogSettings?.datasetKey;
}

export function isMobOpsLogDistrictSettings(
  settings: LayerSettings | null
): settings is MobOpsLogDistrictSettings {
  if (!settings) {
    return false;
  }
  const potentialMobOpsLogSettings = settings as MobOpsLogDistrictSettings;
  return !!potentialMobOpsLogSettings?.selectedFeatureIndexes;
}

export function isMobOpsLogDistrictDiffSettings(
  settings: LayerSettings | null
): settings is MobOpsLogDistrictDiffSettings {
  if (!settings) {
    return false;
  }
  const potentialMobOpsLogSettings = settings as MobOpsLogDistrictDiffSettings;
  return !!potentialMobOpsLogSettings?.timerId;
}

export function isGeoJsonData(data: unknown): data is GeoJsonData {
  const potentialGeoJsonData = data as GeoJsonData;
  return !!potentialGeoJsonData?.properties && !!potentialGeoJsonData?.geoJson;
}

export function isFlowData(data: unknown): data is FlowData {
  const potentialFlowData = data as FlowData;
  return !!potentialFlowData?.locations && !!potentialFlowData?.flows;
}

export function isWmsDataId(value: unknown): value is WmsDataId {
  return wmsDataIds.includes(value as WmsDataId);
}
