import React from "react";
import Paper from "@mui/material/Paper";
import { SxProps, Theme, Typography } from "@mui/material";
import { getTooltipKeyDisplayName } from "../../../tech/utils/DisplayNameHandler";
import { TooltipData } from "../../../store/slices/maps/types";

export interface HoverTooltipProps {
  sx?: SxProps<Theme>;
  data: TooltipData;
}

type Property = {
  key: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function collectProperties(properties: any): Property[] {
  if (!properties) {
    return [];
  }

  const collectedProperties: Property[] = [];
  Object.keys(properties)
    .filter((key) => key !== "tags")
    .forEach((key) => {
      collectedProperties.push({ key, value: properties[key] });
    });
  if (properties.tags) {
    Object.keys(properties.tags).forEach((key) => {
      collectedProperties.push({ key, value: properties.tags[key] });
    });
  }
  return collectedProperties;
}

export default function HoverTooltip({
  sx,
  data,
}: HoverTooltipProps): React.ReactElement {
  const { x, y } = data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const object = data.object as any;

  const tooltipContent = (): React.ReactElement => {
    if (object && object.cluster && object.point_count) {
      return (
        <Typography
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="p"
        >
          {`Anzahl: ${object.point_count}`}
        </Typography>
      );
    }
    const properties = collectProperties(object?.properties);
    if (properties.length > 0) {
      return (
        <>
          {properties.map((property) => (
            <Typography
              key={property.key}
              sx={{
                mb: (theme) => theme.spacing(1),
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              component="p"
            >{`${getTooltipKeyDisplayName(property.key)}: ${
              property.value
            }`}</Typography>
          ))}
        </>
      );
    }
    return (
      <Typography component="p">Keine Informationen vorhanden.</Typography>
    );
  };

  return (
    <Paper
      sx={{
        px: (theme) => theme.spacing(2),
        py: (theme) => theme.spacing(1),
        width: "max-content",
        maxWidth: 300,
        height: "max-content",
        position: "absolute",
        top: y,
        left: x,
        transform: "translate(10px, 0px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        ...sx,
      }}
    >
      {tooltipContent()}
    </Paper>
  );
}

HoverTooltip.defaultProps = {
  sx: undefined,
};
