import {
  format,
  parseISO,
  addMinutes,
  endOfDay,
  getTime,
  startOfDay,
  subMinutes,
} from "date-fns";
import { de } from "date-fns/locale";

function toUtcTimetamp(timestampString: string): string {
  if (timestampString.endsWith("Z")) {
    return timestampString;
  }
  return timestampString.concat("Z");
}

export function formatTimestamp(timestampString: string): string {
  const formatTemplate = "dd.MM.yyyy - HH:mm:ss";
  if (!timestampString) {
    return formatTemplate;
  }
  const utcTimestampString = toUtcTimetamp(timestampString);
  const parsedDate = parseISO(utcTimestampString);
  return format(parsedDate, formatTemplate);
}

export function formatTimestampToTime(timestampString: string): string {
  const formatTemplate = "HH:mm";
  if (!timestampString) {
    return formatTemplate;
  }
  const utcTimestampString = toUtcTimetamp(timestampString);
  const parsedDate = parseISO(utcTimestampString);
  return format(parsedDate, formatTemplate);
}

export function formatTimestampToDate(timestampString: string): string {
  const formatTemplate = "dd.MM.yyyy";
  if (!timestampString) {
    return formatTemplate;
  }
  const utcTimestampString = toUtcTimetamp(timestampString);
  const parsedDate = parseISO(utcTimestampString);
  return format(parsedDate, formatTemplate);
}

export function formatTimestampToDateTime(timestampString: string): string {
  const formatTemplate = "dd.MM.yyyy HH:mm";
  if (!timestampString) {
    return formatTemplate;
  }
  const utcTimestampString = toUtcTimetamp(timestampString);
  const parsedDate = parseISO(utcTimestampString);
  return format(parsedDate, formatTemplate);
}

export function formatTimestampToMonthYear(timestampString: string): string {
  const formatTemplate = "MMMM yyyy";
  if (!timestampString) {
    return formatTemplate;
  }
  const utcTimestampString = toUtcTimetamp(timestampString);
  const parsedDate = parseISO(utcTimestampString);
  return format(parsedDate, formatTemplate, { locale: de });
}

export function isoTimestampToDate(timestamp: string): Date | null {
  if (!timestamp) {
    return null;
  }
  return parseISO(timestamp);
}

export function getStartOfDayMilliseconds(date: Date): number {
  const startOfDayDate = startOfDay(date);
  return getTime(startOfDayDate);
}

export function getEndOfDayMilliseconds(date: Date): number {
  const endOfDayDate = endOfDay(date);
  return getTime(endOfDayDate);
}

export function toUTC(date: Date) {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
}
