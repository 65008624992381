import React from "react";
import { shallowEqual } from "react-redux";
import { Autocomplete, Box, TextField } from "@mui/material";
import { MapFeatureKey } from "../../../../api/map-feature-keys/types";
import { LayerId } from "../../../../store/slices/layers/types";
import ControllerBase from "./ControllerBase";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectLayerById,
  selectPoiSettingsById,
} from "../../../../store/slices/layers/selectors";
import { updatePoiSettings } from "../../../../store/slices/layers/slice";
import { selectAllMapFeatureKeys } from "../../../../store/slices/map-feature-keys/slice";
import { dispatchFetchLayerData } from "../../../../store/slices/layers/factory";

interface PoiControllerProps {
  layerId: LayerId;
}

export default function PoiController({
  layerId,
}: PoiControllerProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const poiLayerSettings = useAppSelector((state) =>
    selectPoiSettingsById(state, layerId)
  );
  const layerLabel = useAppSelector(
    (state) => selectLayerById(state, layerId).label
  );
  const mapFeatureKeys = useAppSelector(
    (state) => selectAllMapFeatureKeys(state),
    shallowEqual
  );

  const handleChangePoiKey = (
    event: React.SyntheticEvent<Element, Event>,
    value: MapFeatureKey | null
  ): void => {
    dispatch(updatePoiSettings({ id: layerId, mapFeatureKey: value }));
    dispatchFetchLayerData(layerId, true);
  };

  // TODO: implement autocomplete with virtualization to show all poi keys even with quantity = 0

  return (
    <ControllerBase sx={{ width: 200 }} label={layerLabel}>
      <Autocomplete
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
        options={mapFeatureKeys
          .filter((mapFeatureKey) => mapFeatureKey.totalNumber > 0)
          .sort((a, b) => -b.name.localeCompare(a.name))}
        getOptionDisabled={(option) => option.totalNumber === 0}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={() => true}
        value={poiLayerSettings?.mapFeatureKey || null}
        onChange={handleChangePoiKey}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box {...props} component="li" key={option.id}>
            {option.name} ({option.totalNumber})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label="Point of Interest"
            variant="outlined"
          />
        )}
      />
    </ControllerBase>
  );
}
