import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import {
  HeatmapData,
  FlowData,
  MarkerData,
  GeoJsonLayerProperties,
  HeatmapDataInterval,
} from "./types";

export async function getMarkerData(
  id: string
): Promise<AxiosResponse<MarkerData>> {
  return AxiosClient.get(`/core/markers/?id=${id}`);
}

export async function getHeatmapData(
  dataset: string,
  attrib: string,
  interval: HeatmapDataInterval,
  fromMs: number,
  toMs: number
): Promise<AxiosResponse<HeatmapData>> {
  return AxiosClient.get(
    `/core/heatmaps/?from_ms=${fromMs}&to_ms=${toMs}&attrib=${attrib}&dataset=${dataset}&interval=${interval}`
  );
}

export async function getGeoJsonData(
  dataCategoryId: string
): Promise<AxiosResponse<ArrayBuffer>> {
  return AxiosClient.get(`/geo-jsons?data_category_id=${dataCategoryId}`, {
    responseType: "arraybuffer",
  });
}

export async function getGeoJsonProperties(
  dataCategoryId: string
): Promise<AxiosResponse<GeoJsonLayerProperties>> {
  return AxiosClient.get(
    `/geo-jsons/properties?data_category_id=${dataCategoryId}`
  );
}

export async function getTazesGeoJsonData(): Promise<
  AxiosResponse<ArrayBuffer>
> {
  return AxiosClient.get(`/geo-jsons`, {
    responseType: "arraybuffer",
  });
}

export async function getFlowData(
  dataset: string,
  flowCount: number,
  fromMs: number,
  toMs: number
): Promise<AxiosResponse<FlowData>> {
  return AxiosClient.get(
    `/core/flows/?from_ms=${fromMs}&to_ms=${toMs}&dataset=${dataset}&flow_count=${flowCount}`
  );
}

export async function getMobOpsLogRoutesData(
  fileName: string,
  format?: string,
  vehicle?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> {
  let url = `/routes/${fileName}`;
  if (format || vehicle !== undefined) {
    url = url.concat(`?`);
    if (format) {
      url = url.concat(`format=${format}`);
    }
    if (format && vehicle !== undefined) {
      url = url.concat(`&`);
    }
    if (vehicle !== undefined) {
      url = url.concat(`vehicle=${vehicle}`);
    }
  }
  return AxiosClient.get(url);
}

export async function getMobOpsLogClusterData(
  groupKey: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> {
  const url = `/clustered-route-centers?group_key=${groupKey}`;
  return AxiosClient.get(url);
}

export async function getMobOpsLogDistrictData(
  clusterGroupKey: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> {
  const url = `/delivery-districts?cluster_group_key=${clusterGroupKey}`;
  return AxiosClient.get(url);
}
