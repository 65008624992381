/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any usages

// import { GroupedLayers } from "./types";

export function equalGroupedLayers(
  prevGroupedLayers: any,
  nextGroupedLayers: any
): boolean {
  const prevKeys = Object.keys(prevGroupedLayers);
  const nextKeys = new Set(Object.keys(prevGroupedLayers));
  return (
    prevKeys.length === nextKeys.size &&
    prevKeys.every(
      (prevKey) =>
        nextKeys.has(prevKey) &&
        prevGroupedLayers[Number(prevKey)].length ===
          nextGroupedLayers[Number(prevKey)].length
    )
  );
}
