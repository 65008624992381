/* eslint-disable no-param-reassign */
import {
  HeatmapSettings,
  PoiSettings,
  TimeseriesSettings,
  DeliverySettings,
  MobOpsLogRouteSettings,
  MobOpsLogDistrictSettings,
  MobOpsLogDistrictDiffSettings,
} from "../../../components/map/layer/types";
import type { RootState } from "../../store";
import { RequestStatus } from "../../types";
import {
  DebugLayerCategoryId,
  LayerCategoryId,
} from "../layer-categories/types";
import { MapConfigId } from "../maps/types";
import {
  isHeatmapSettings,
  isPoiSettings,
  isTimeseriesSettings,
  isDeliverySettings,
  isMobOpsLogDistrictSettings,
  isMobOpsLogRouteSettings,
  isMobOpsLogDistrictDiffSettings,
} from "./type-guards";
import {
  GroupedLayers,
  Layer,
  LayerData,
  LayerId,
  LayerState,
  ObjectsByLayerId,
} from "./types";

export const selectAllLayers = (state: RootState): Layer[] =>
  Object.values(state.layerReducer.layersById);

export const selectLayersByCategoryId = (
  state: RootState,
  categoryId: LayerCategoryId | DebugLayerCategoryId
): Layer[] =>
  selectAllLayers(state).filter((layer) => layer.categoryId === categoryId);

export const selectGroupedLayersByCategoryId = (
  state: RootState,
  categoryId: LayerCategoryId | DebugLayerCategoryId
): GroupedLayers =>
  selectAllLayers(state)
    .filter((layer) => layer.categoryId === categoryId)
    .reduce((groupedLayers: GroupedLayers, layer) => {
      groupedLayers[layer.group] = [
        ...(groupedLayers[layer.group] || []),
        layer,
      ];
      return groupedLayers;
    }, {});

export const selectAllSelectedLayers = (state: RootState): Layer[] =>
  selectAllLayers(state).filter((layer) => layer.selected);

export const selectAllSelectedLayersByMapConfigId = (
  state: RootState,
  mapConfigId: MapConfigId
): Partial<ObjectsByLayerId<Layer>> =>
  selectAllSelectedLayers(state)
    .filter((layer) => layer.mapConfigId === mapConfigId)
    .reduce((layers: Partial<ObjectsByLayerId<Layer>>, layer: Layer) => {
      layers[layer.id] = layer;
      return layers;
    }, {});

export const selectLayerById = (state: RootState, id: LayerId): Layer =>
  state.layerReducer.layersById[id];

export const selectLayerDataByIds = (
  state: RootState,
  ids: LayerId[]
): Partial<ObjectsByLayerId<LayerData>> =>
  ids.reduce((layerData: Partial<ObjectsByLayerId<LayerData>>, id: LayerId) => {
    layerData[id] = state.layerReducer.dataById[id];
    return layerData;
  }, {});

export const selectLayerStatusById = (
  state: RootState,
  id: LayerId
): RequestStatus => state.layerReducer.statusesById[id];

export const getHeatmapSettingsById = (
  state: LayerState,
  id: LayerId
): HeatmapSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let heatmapSettings: HeatmapSettings | null = null;
  if (isHeatmapSettings(layerSettings)) {
    heatmapSettings = layerSettings as HeatmapSettings;
  }
  return heatmapSettings;
};

export const getDeliverySettingsById = (
  state: LayerState,
  id: LayerId
): PoiSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let deliverySettings: PoiSettings | null = null;
  if (isDeliverySettings(layerSettings)) {
    deliverySettings = layerSettings as DeliverySettings;
  }
  return deliverySettings;
};

export const selectDeliverySettingsById = (
  state: RootState,
  id: LayerId
): DeliverySettings | null => getDeliverySettingsById(state.layerReducer, id);

export const selectHeatmapSettingsById = (
  state: RootState,
  id: LayerId
): HeatmapSettings | null => getHeatmapSettingsById(state.layerReducer, id);

export const getPoiSettingsById = (
  state: LayerState,
  id: LayerId
): PoiSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let poiSettings: PoiSettings | null = null;
  if (isPoiSettings(layerSettings)) {
    poiSettings = layerSettings as PoiSettings;
  }
  return poiSettings;
};

export const selectPoiSettingsById = (
  state: RootState,
  id: LayerId
): PoiSettings | null => getPoiSettingsById(state.layerReducer, id);

export const getTimeseriesSettingsById = (
  state: LayerState,
  id: LayerId
): TimeseriesSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let timeseriesSettings: TimeseriesSettings | null = null;
  if (isTimeseriesSettings(layerSettings)) {
    timeseriesSettings = layerSettings as TimeseriesSettings;
  }
  return timeseriesSettings;
};

export const selectTimeseriesSettingsById = (
  state: RootState,
  id: LayerId
): TimeseriesSettings | null =>
  getTimeseriesSettingsById(state.layerReducer, id);

export const getMobOpsLogRouteSettingsById = (
  state: LayerState,
  id: LayerId
): MobOpsLogRouteSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let mobOpsLogSettings: MobOpsLogRouteSettings | null = null;
  if (isMobOpsLogRouteSettings(layerSettings)) {
    mobOpsLogSettings = layerSettings as MobOpsLogRouteSettings;
  }
  return mobOpsLogSettings;
};

export const selectMobOpsLogRouteSettingsById = (
  state: RootState,
  id: LayerId
): MobOpsLogRouteSettings | null =>
  getMobOpsLogRouteSettingsById(state.layerReducer, id);

export const getMobOpsLogDistrictSettingsById = (
  state: LayerState,
  id: LayerId
): MobOpsLogDistrictSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let mobOpsLogSettings: MobOpsLogDistrictSettings | null = null;
  if (isMobOpsLogDistrictSettings(layerSettings)) {
    mobOpsLogSettings = layerSettings as MobOpsLogDistrictSettings;
  }
  return mobOpsLogSettings;
};

export const selectMobOpsLogDistrictSettingsById = (
  state: RootState,
  id: LayerId
): MobOpsLogDistrictSettings | null =>
  getMobOpsLogDistrictSettingsById(state.layerReducer, id);

export const getMobOpsLogDistrictDiffSettingsById = (
  state: LayerState,
  id: LayerId
): MobOpsLogDistrictDiffSettings | null => {
  const layerSettings = state.dataById[id].settings;
  let mobOpsLogSettings: MobOpsLogDistrictDiffSettings | null = null;
  if (isMobOpsLogDistrictDiffSettings(layerSettings)) {
    mobOpsLogSettings = layerSettings as MobOpsLogDistrictDiffSettings;
  }
  return mobOpsLogSettings;
};

export const selectMobOpsLogDistrictDiffSettingsById = (
  state: RootState,
  id: LayerId
): MobOpsLogDistrictDiffSettings | null =>
  getMobOpsLogDistrictDiffSettingsById(state.layerReducer, id);
