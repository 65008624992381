/* eslint-disable @typescript-eslint/no-explicit-any */
import { LayerId } from "../../store/slices/layers/types";

const defaultAlpha = 255;

const colors = [
  [255, 0, 0, defaultAlpha],
  [255, 165, 0, defaultAlpha],
  [255, 255, 0, defaultAlpha],
  [128, 255, 0, defaultAlpha],
  [0, 255, 0, defaultAlpha],
  [0, 255, 128, defaultAlpha],
  [0, 255, 255, defaultAlpha],
  [0, 128, 255, defaultAlpha],
  [128, 0, 255, defaultAlpha],
  [255, 0, 255, defaultAlpha],
  [255, 192, 203, defaultAlpha],
  [0, 128, 128, defaultAlpha],
  [128, 128, 128, defaultAlpha],
  [0, 51, 153, defaultAlpha],
  [0, 0, 0, defaultAlpha],
  [255, 215, 0, defaultAlpha],
  [204, 0, 255, defaultAlpha],
  [0, 51, 0, defaultAlpha],
  [255, 127, 80, defaultAlpha],
  [139, 69, 19, defaultAlpha],
  [211, 110, 112, defaultAlpha],
  [85, 107, 47, defaultAlpha],
  [0, 0, 128, defaultAlpha],
  [128, 0, 0, defaultAlpha],
  [130, 113, 255, defaultAlpha],
  [80, 200, 120, defaultAlpha],
  [0, 201, 87, defaultAlpha],
  [255, 218, 185, defaultAlpha],
  [255, 69, 0, defaultAlpha],
  [0, 103, 165, defaultAlpha],
  [46, 139, 87, defaultAlpha],
  [238, 130, 238, defaultAlpha],
  [188, 143, 143, defaultAlpha],
  [218, 165, 32, defaultAlpha],
  [218, 112, 214, defaultAlpha],
  [250, 128, 114, defaultAlpha],
  [75, 0, 130, defaultAlpha],
  [255, 105, 180, defaultAlpha],
  [0, 128, 128, defaultAlpha],
  [0, 191, 255, defaultAlpha],
  [238, 232, 170, defaultAlpha],
  [128, 0, 128, defaultAlpha],
  [255, 69, 0, defaultAlpha],
  [255, 140, 0, defaultAlpha],
  [0, 128, 64, defaultAlpha],
  [75, 0, 0, defaultAlpha],
  [0, 128, 192, defaultAlpha],
  [30, 30, 30, defaultAlpha],
  [255, 255, 200, defaultAlpha],
  [0, 128, 0, defaultAlpha],
  [254, 239, 50, defaultAlpha],
];

export function randomHexColor(): string {
  return Math.floor(Math.random() * 16777215).toString(16);
}

export function hexToRgba(hex: string, alpha: number): number[] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        alpha,
      ]
    : [0, 0, 0, alpha];
}

export function getColorByIndex(idx: number, alpha: number): number[] {
  if (idx < colors.length) {
    const color = colors[idx];
    return [color[0], color[1], color[2], alpha];
  }
  const hex = randomHexColor();
  return hexToRgba(hex, alpha);
}

export function getMobOpsLogFeatureColor(
  feature: any,
  alpha: number
): number[] {
  const properties = feature?.properties;
  if (properties) {
    const { vehicle, cluster, id } = properties;
    if (vehicle !== undefined && vehicle !== null) {
      // routes layer
      return getColorByIndex(vehicle, alpha);
    }
    if (cluster !== undefined && cluster !== null) {
      // cluster layer
      return getColorByIndex(cluster, alpha);
    }
    if (id !== undefined && id !== null) {
      // district layer
      return getColorByIndex(id, 100);
    }
  }
  return getColorByIndex(-1, alpha);
}

export function getMobOpsLogGeoJsonLayerStyle(layerId: LayerId): any {
  const sLayerId = String(layerId);
  const isFutureLayer = sLayerId.startsWith("mobopslog-future-");
  const isDistrictLayer = sLayerId.endsWith("-result");
  if (isFutureLayer) {
    return {
      getFillColor: (feature: any) => getMobOpsLogFeatureColor(feature, 255),
      getLineColor: (feature: any) => getMobOpsLogFeatureColor(feature, 255),
      getPointRadius: () => 4,
      getLineWidth: () => (isDistrictLayer ? 4 : 8),
    };
  }
  return {
    getFillColor: (feature: any) => getMobOpsLogFeatureColor(feature, 0),
    getLineColor: (feature: any) => getMobOpsLogFeatureColor(feature, 255),
    getPointRadius: () => 5,
    getLineWidth: () => (isDistrictLayer ? 4 : 8),
  };
}

export function getMobOpsLogDistrictDiffFeatureColor(
  feature: any,
  alpha: number
): number[] {
  const properties = feature?.properties;
  if (properties) {
    const { id } = properties;
    if (id !== undefined && id !== null) {
      // district layer
      return getColorByIndex(id, alpha);
    }
  }
  return getColorByIndex(-1, alpha);
}

export function getMobOpsLogGeoJsonDistrictDiffLayerStyle(alpha: number): any {
  return {
    getFillColor: (feature: any) =>
      getMobOpsLogDistrictDiffFeatureColor(feature, alpha),
    getLineColor: (feature: any) =>
      getMobOpsLogDistrictDiffFeatureColor(feature, alpha),
    getPointRadius: () => 5,
    getLineWidth: () => 4,
  };
}
