import { HeatmapDataInterval } from "../../../api/layers/types";
import { MapFeatureKey } from "../../../api/map-feature-keys/types";
import {
  HeatmapSettings,
  MobOpsLogDistrictDiffSettings,
  MobOpsLogDistrictSettings,
  MobOpsLogRouteSettings,
  PoiSettings,
  TimeseriesSettings,
} from "../../../components/map/layer/types";
import { RequestStatus } from "../../types";
import {
  DebugLayerCategoryId,
  LayerCategoryId,
} from "../layer-categories/types";
import { MapConfigId } from "../maps/types";

export const layerIds = [
  "induction-loops",
  "traffic-information",
  "occupancy-avg",
  "occupancy-daily",
  "flow-avg",
  "flow-daily",
  "capacity",
  "road-network",
  "bike-network",
  "city-areas",
  "points-of-interest",
  "commuters",
  "tazes",
  "zensus-1km-wms",
  "zensus-100m",
  "school-stats",
  "mobopslog-routes",
  "mobopslog-routes-line",
  "mobopslog-clusters",
  "mobopslog-result",
  "mobopslog-future-routes",
  "mobopslog-future-routes-line",
  "mobopslog-future-clusters",
  "mobopslog-future-result",
  "mobopslog-diff-result",
] as const;
export type LayerId = typeof layerIds[number];

export type ObjectsByLayerId<T> = {
  [id in Partial<LayerId>]: T;
};

export type GroupedLayers = {
  [group: number]: Layer[];
};

export interface Layer {
  id: LayerId;
  categoryId: LayerCategoryId | DebugLayerCategoryId;
  mapConfigId: MapConfigId;
  group: number;
  label: string;
  selected: boolean;
  editing: boolean;
  iconId: string;
  editComponentId?: string;
  disableEditOnSelect?: boolean;
  additionalComponentId?: string;
}

export type LayerData = {
  settings: LayerSettings | null;
  data: unknown | null;
};

export type LayerSettings =
  | HeatmapSettings
  | PoiSettings
  | TimeseriesSettings
  | MobOpsLogRouteSettings
  | MobOpsLogDistrictSettings
  | MobOpsLogDistrictDiffSettings;

export interface LayerState {
  layersById: ObjectsByLayerId<Layer>;
  dataById: ObjectsByLayerId<LayerData>;
  statusesById: ObjectsByLayerId<RequestStatus>;
}

export interface HeatmapSettingsValueUpdate {
  id: LayerId;
  value: number;
  name: keyof HeatmapSettings;
}

export interface PoiSettingsUpdate {
  id: LayerId;
  mapFeatureKey: MapFeatureKey | null;
}

export interface DeliverySettingsUpdate {
  id: LayerId;
  mapFeatureKey: MapFeatureKey | null;
}

export interface TimeseriesSettingsUpdate {
  id: LayerId;
  dataRef: string | null;
}

export interface MobOpsLogRouteSettingsUpdate {
  id: LayerId;
  datasetKey: string | null;
}

export interface MobOpsLogDistrictDataUpdate {
  id: LayerId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoJson: any;
}

export interface MobOpsLogDistrictSettingsUpdate {
  id: LayerId;
  selectedFeatureIndexes: number[];
}

export interface MobOpsLogDistrictDiffSettingsUpdate {
  id: LayerId;
  clusterGroupKey: "clusters-now" | "clusters-2030";
  colorAlpha: number;
  timerId: number | null;
}

export interface MobOpsLogDistrictDiffColorAlphaUpdate {
  id: LayerId;
  colorAlpha: number;
}

export interface FetchMarkerDataParams {
  layerId: LayerId;
  dataId: string;
}

export interface FetchHeatmapDataParams {
  layerId: LayerId;
  dataset: string;
  attrib: string;
  interval: HeatmapDataInterval;
  fromMs: number;
  toMs: number;
}

export interface FetchGeoJsonDataParams {
  layerId: LayerId;
  dataCategoryId: string;
}

export interface FetchTazesGeoJsonDataParams {
  layerId: LayerId;
}

export interface FetchFlowDataParams {
  layerId: LayerId;
  dataset: string;
  flowCount: number;
  fromMs: number;
  toMs: number;
}

export interface FetchMobOpsLogRoutesDataParams {
  layerId: LayerId;
  fileName: string;
  format?: string;
  vehicle?: number;
}

export interface FetchMobOpsLogClusterDataParams {
  layerId: LayerId;
  groupKey: string;
}

export interface FetchMobOpsLogDistrictDataParams {
  layerId: LayerId;
  clusterGroupKey: string;
}
